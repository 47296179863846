import * as React from "react";
import { Header, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const DownloadPage = () => {
  return (
    <Container>
      <Segment vertical className="aboutooti">
        <Header as="h2">
          <Icon name="download" />
          <Header.Content>
            Download
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical className="aboutooti">
        <h2>tooti is available on Android and iOS devices</h2>        
        <br></br>
        <br></br>
        <h3>You may download tooti on the following links:</h3>
        <p>
          <Icon name="android"></Icon>
           <a href="https://play.google.com/store/apps/details?id=cc.tooti.aw" target="blank" >Android - tooti</a>
        </p>
        <br></br>
        <p>   
          <Icon name="apple"></Icon>
          <a href="https://apps.apple.com/us/app/tooti-food-insight/id1485172324" target="blank" >iOS - tooti</a>
        </p>
        <br></br>
        <br></br>
        <p>*tooti app requires internet connection and access to camera</p>
        <p>
          For support quetions, email us 
          <a href="mailto:support@tooti.cc" target="blank"> support@tooti.cc</a>
        </p>
      </Segment>
    </Container>
  );
};

export default withLayout(DownloadPage);
